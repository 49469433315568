import classes from "./ContactDeleteConfirmPopup.module.scss";
import {MdOutlineClose} from "react-icons/md";
import React from "react";
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import {toast, ToastContainer} from 'react-toastify';
import {createGradient} from "../../../../../shared/Constants";

const ContactDeleteConfirmPopup = (props) => {

  const onDeleteHandler = successCallback => {
    try {
      props.onDeleteContact(props.contactId,successCallback);
      showToast("Contact deleted successfully!");
      props.onClose();
    }catch (err) {
      showToast("Something went wrong!")
      props.onClose();
    }
  }

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style:{background:`linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color,35)} 100%)`}
    });
  };

  return (
    <div className={classes.DeleteConfirmation}>
      <ToastContainer />
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5" />
        </div>
      </div>
      <p className={classes.ConfirmationText}>
        Delete Confirmation
      </p>
      <p className={classes.InfoText}>
        Are you sure you want to Delete this Contact?
      </p>
      <button className={classes.DeleteButton} onClick={onDeleteHandler}>
        Yes, Delete
      </button>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onDeleteContact: (contactId,successCallback) => dispatch(actions.deleteContact(contactId,successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(ContactDeleteConfirmPopup);

import classes from "./ThirdStep.module.scss";
import {BsShieldLockFill} from "react-icons/bs";
import Input from "../../../../UI/Input/Input";
import React from "react";

const ThirdStep =(props) => {
  return(
    <div className={classes.ThirdStep}>
      <div>
        <h1>Create New Password</h1>
        <div className={classes.IconWrapper}>
          <BsShieldLockFill className={classes.Icon}/>
        </div>

        <p className={classes.Paragraph}>
          Your New Password Must be
          <br/>
          From Previously Used
          <br/>
          Password
        </p>
      </div>

      <Input
        type="password"
        placeholder="New Password"
        value={props.password}
        onChange={event => props.setPassword(event.target.value)}
      />
      <Input
        type="password"
        placeholder="Confirm Password"
        value={props.confirmPassword}
        onChange={event => props.setConfirmPassword(event.target.value)}
      />
    </div>
  )
}

export default ThirdStep;

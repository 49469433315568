import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import './index.css';
import App from './App';

import {
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';

import authReducer from './store/reducers/auth';
import homeReducer from './store/reducers/home';
import profileReducer from './store/reducers/profile';
import contactReducer from './store/reducers/contact';
import linkReducer from './store/reducers/link';
import accountReducer from './store/reducers/account';
import teamsReducer from './store/reducers/teams';

const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  profile: profileReducer,
  contact: contactReducer,
  link: linkReducer,
  account: accountReducer,
  teams: teamsReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

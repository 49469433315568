import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import React from "react";
import {CgMenuLeft, CgMenuRight} from "react-icons/cg";
import classes from "./SideDrawe.module.scss";
import {BiArrowBack, BiBuildings} from "react-icons/bi";
import {FiUsers} from "react-icons/fi";
import {useLocation, useNavigate} from "react-router-dom";
import {BsFillCreditCardFill} from "react-icons/bs";
import {MdOutlineBrandingWatermark} from "react-icons/md";

const SideDrawer = ({color}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const onNavigate = path => {
    navigate(path);
  };
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <div style={{display:location.pathname === '/home/teams/create-profile' ? "none" : null}}>
      <button onClick={toggleDrawer} className={classes.Button}>
        {isOpen ? <CgMenuRight/> : <CgMenuLeft/>}
      </button>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='left'
        className={classes.DrawerContainer}
      >
        <div className={classes.Drawer}>
          <div className={classes.DrawerTitle}>
            <h1>TEAMS</h1>
            <BiArrowBack onClick={toggleDrawer}/>
          </div>
          <div
            className={location.pathname === '/home/teams' ? classes.DrawerItemSelected : classes.DrawerItemNotSelected}
            style={{background: location.pathname === '/home/teams' ? color : ''}}
            onClick={() => {
              onNavigate('/home/teams');
              toggleDrawer()
            }}>
            <FiUsers/>
            <span>Users</span>
          </div>

          <div
            className={location.pathname === '/home/teams/profiles' ? classes.DrawerItemSelected : classes.DrawerItemNotSelected}
            style={{background: location.pathname === '/home/teams/profiles' ? color : ''}}
            onClick={() => {
              onNavigate('/home/teams/profiles');
              toggleDrawer()
            }}
          >
            <BsFillCreditCardFill/>
            <span>Profiles</span>
          </div>

          <div
            className={location.pathname === '/home/teams/company-details' ? classes.DrawerItemSelected : classes.DrawerItemNotSelected}
            style={{background:location.pathname === '/home/teams/company-details' ? color: ''}}
            onClick={() => {
              onNavigate('/home/teams/company-details');
              toggleDrawer()
            }}
          >
            <BiBuildings/>
            <span>Company Details</span>
          </div>

          <div
            className={location.pathname === '/home/teams/branding' ? classes.DrawerItemSelected : classes.DrawerItemNotSelected}
            style={{background:location.pathname === '/home/teams/branding' ? color: ''}}
            onClick={() => {
              onNavigate('/home/teams/branding');
              toggleDrawer()
            }}
          >
            <MdOutlineBrandingWatermark/>
            <span>Branding</span>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default SideDrawer;

import React from 'react';
import classes from './VisibilityDropdown.module.scss';
import {FiChevronDown, FiGlobe, FiLock} from 'react-icons/fi';
import {MdDone} from 'react-icons/md';
import {VISIBILITY} from '../../../shared/Constants';

const VisibilityDropdown = props => {
  const [dropDownVisible, setDropDownVisibility] = React.useState(false);

  const useOutsideAlerter = ref => {
    React.useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDownVisibility(false);
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  const onChangeVisibilityHandler = visibility => {
    props.onChangeVisibility(visibility);
    setDropDownVisibility(false);
  };

  return (
    <div className={classes.VisibilityDropdown}>
      <div
        className={classes.DropIconContainer}
        onClick={() => setDropDownVisibility(true)}
      >
        {props.visibility === VISIBILITY.PRIVATE ? (
          <FiLock color="#a7a7a7" size={16} />
        ) : (
          <FiGlobe color="#a7a7a7" size={16} />
        )}
        <FiChevronDown color="#a7a7a7" size={16} />
      </div>
      {dropDownVisible ? (
        <div className={classes.DropDownPanel} ref={wrapperRef}>
          <div onClick={() => onChangeVisibilityHandler(VISIBILITY.PRIVATE)}>
            <FiLock color="#a7a7a7" size={16} />
            <p>Private</p>
            <MdDone
              size={18}
              color={
                props.visibility === VISIBILITY.PRIVATE ? props.color : '#3d3d3d'
              }
            />
          </div>
          <div onClick={() => onChangeVisibilityHandler(VISIBILITY.PUBLIC)}>
            <FiGlobe color="#a7a7a7" size={16} />
            <p>Public</p>
            <MdDone
              size={18}
              color={
                props.visibility === VISIBILITY.PUBLIC ? props.color : '#3d3d3d'
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default VisibilityDropdown;

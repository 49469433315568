import React from 'react';
import classes from './RegisterForm.module.scss';
import Input from '../../../UI/Input/Input';
import {RiUser3Line, RiUserLocationLine} from 'react-icons/ri';
import {MdOutlineEmail, MdOutlineGeneratingTokens} from 'react-icons/md';
import {useNavigate,useSearchParams} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import validator from 'validator';
import * as actions from '../../../../store/actions';
import {connect} from 'react-redux';
import {ClipLoader} from 'react-spinners';
import ct from 'countries-and-timezones';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {isValidPhoneNumber} from 'libphonenumber-js';
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import {AiFillCloseCircle} from "react-icons/ai";


const RegisterForm = props => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const tenantName = process.env.REACT_APP_TENANT_NAME;
  const [username, setUsername] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [contactNo, setContact] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [registrationToken, setRegistrationToken] = React.useState(token || '');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [isUserNameAvailable, setIsUserNameAvailable] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);

  console.log(process.env.REACT_APP_TENANT_NAME)
  const getCountry =  () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz)
    return obj.id.toLowerCase();
  }

  React.useEffect(() => {
    if (token === null){
      setIsDisabled(false)
    }else{
      setIsDisabled(true)
    }
  },[]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await props.onIsUserNameExist(username);
        if (username.length < 4){
          setIsUserNameAvailable(true);
        }else{
          setIsUserNameAvailable(response);
        }

      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [username, props.onIsUserNameExist]);

  const onRegisterHandler = () => {
    if (formIsValid()) {
      props.onRegister(
        firstName,
        'PERSONAL',
        lastName,
        '0',
        contactNo,
        email,
        password,
        registrationToken,
        tenantName,
        username,
        successCallback,
        showToast,
      );
    }
  };

  const successCallback = () => {
    showToast('Registered Successfully!');
    navigate('/');
  };

  const formIsValid = () => {
    const usernameRegex = /^\w+$/;
    const nameRegex = /^[a-zA-Z]+$/;

    if (username === '') {
      showToast('User Name cannot be empty!');
      return false;
    } else if (!username.match(usernameRegex)) {
      showToast('Username is only letters and numbers!');
      return false;
    } else if (firstName === '') {
      showToast('Firstname cannot be empty!');
      return false;
    } else if (!firstName.match(nameRegex)) {
      showToast('First name is Invalid!');
      return false;
    } else if (lastName === '') {
      showToast('Lastname cannot be empty!');
      return false;
    } else if (contactNo === '') {
      showToast('Contact cannot be empty!');
      return false;
    } else if (!isValidPhoneNumber(contactNo)) {
        showToast('Invalid phone number');
        return false;
    } else if (email === '') {
      showToast('Email cannot be empty');
      return false;
    } else if (!validator.isEmail(email)) {
      showToast('Please enter a valid email');
      return false;
    } else if (password === '') {
      showToast('Password cannot be empty!');
      return false;
    } else if (registrationToken === '') {
      showToast('Registration Token cannot be empty!');
      return false;
    } else if (confirmPassword === '') {
      showToast('Confirm-password cannot be empty!');
      return false;
    } else if (password !== confirmPassword) {
      showToast('Passwords do not match!');
      return false;
    } else {
      return true;
    }
  };

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
    });
  };

  const handlePhoneChange = (phone) => {
    setContact('+'+phone);
  };
  return (
    <div className={classes.RegisterFormContainer}>
      <div className={classes.RegisterForm}>
        <div className={classes.RegisterCards}>
          <div>
            <div className={classes.Input}>
              <div className={classes.IconContainer}><RiUser3Line/></div>
              <input
                type={'text'}
                placeholder="Username"
                onChange={event => setUsername(event.target.value)}
                value={username}
              />
                <div
                  className={classes.TickIcon}
                >
                  {username.length === 0 ? null :
                    (isUserNameAvailable ?
                      <AiFillCloseCircle color={'#ff3b3b'}/>
                      : <IoMdCheckmarkCircleOutline color={'rgb(113 191 63)'}/>)
                  }

                </div>
            </div>
            <Input
              type="text"
              placeholder="First name"
              icon={<RiUser3Line/>}
              value={firstName}
              onChange={event => setFirstName(event.target.value.replace(/[^a-zA-Z]+/g, ''))}
            />
            <Input
              type="text"
              placeholder="Last name"
              value={lastName}
              icon={<RiUserLocationLine/>}
              onChange={event => setLastName(event.target.value.replace(/[^a-zA-Z]+/g, ''))}
            />
            <div className={classes.Input}>
              <PhoneInput
                country={getCountry()}
                countryCodeEditable={false}
                value={contactNo}
                onChange={handlePhoneChange}
                enableSearch={true}
                disableSearchIcon={true}
                inputClass={classes.PhoneInput}
                buttonClass={classes.ButtonClass}
                dropdownClass={classes.DropdownClass}
                searchClass={classes.SearchClass}
              />
            </div>
          </div>
          <div>
            <Input
              type="text"
              placeholder="Registration Token"
              icon={<MdOutlineGeneratingTokens/>}
              value={registrationToken}
              onChange={event => setRegistrationToken(event.target.value)}
              isDisabled={isDisabled}
            />

            <Input
              type="text"
              placeholder="Email Address"
              icon={<MdOutlineEmail/>}
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
            <Input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={event => setConfirmPassword(event.target.value)}
            />
          </div>
        </div>

        {props.loading ? (
          <div className={classes.SpinnerContainer}>
            <ClipLoader color={'#f5833b'} loading={true} size={20}/>
          </div>
        ) : (

          <button
            className={classes.RegisterButton}
            onClick={onRegisterHandler}
          >
            Register
          </button>
        )}

        <div
          className={classes.AlreadyHaveAccount}
          onClick={() => navigate('/')}
        >
          <p>
            Already have an account? <span>Log In</span>
          </p>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onIsUserNameExist: username => dispatch(actions.isUserNameExist(username)),
    onRegister: (
      firstName,
      accountType,
      lastName,
      companyId,
      contactNo,
      email,
      password,
      registrationToken,
      tenantName,
      userName,
      successCallback,
      showToast,
    ) =>
      dispatch(
        actions.register(
          firstName,
          accountType,
          lastName,
          companyId,
          contactNo,
          email,
          password,
          registrationToken,
          tenantName,
          userName,
          successCallback,
          showToast,
        ),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);

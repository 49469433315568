import classes from "./FirstStep.module.scss";
import {GiPadlock} from "react-icons/gi";
import Input from "../../../../UI/Input/Input";
import {MdEmail} from "react-icons/md";
import React from "react";
import TryAnotherWayPopup from "./TryAnotherWayPopup/TryAnotherWayPopup";
import Backdrop from "../../../../UI/Backdrop/Backdrop";

const FirstStep = (props) => {
  const [tryAnotherWayPopupOpen, setTryAnotherWayPopupOpen ] = React.useState(false);

  return(
    <div className={classes.FirstStep}>
      {tryAnotherWayPopupOpen ? (
        <Backdrop onClick={() => setTryAnotherWayPopupOpen(false)}>
          <TryAnotherWayPopup
            onClose={() => {
              setTryAnotherWayPopupOpen(false)
            }
            }
          />
        </Backdrop>
      ) : null}
      <div>
        <h1>Forgot Password?</h1>
        <div className={classes.IconWrapper}>
          <GiPadlock className={classes.Icon}/>
        </div>

        <p className={classes.Paragraph}>
          Please Enter your Email Address
          <br/>
          to Receive a Verification Code
        </p>
      </div>

      <Input
        type="text"
        placeholder="Email"
        icon={<MdEmail/>}
        value={props.email}
        onChange={event => props.setEmail(event.target.value)}
      />
      <span
        className={classes.AnotherWay}
        //onClick={()=>setTryAnotherWayPopupOpen(true)}
        onClick={()=>window.open('https://www.commercialprime.co.uk/contact-us','_blank')}
      >Try Another way
      </span>
    </div>
  )
}

export default FirstStep;

import React from 'react';
import classes from './AddFieldModal.module.scss';
import {IconPickerItem, iconList} from 'react-fa-icon-picker';
import Backdrop from '../../UI/Backdrop/Backdrop';
import {MdOutlineClose} from 'react-icons/md';
import {FiAward, FiList, FiSearch} from 'react-icons/fi';
import {toast, ToastContainer} from 'react-toastify';
import * as actions from '../../../store/actions';
import {connect} from 'react-redux';
import {adjustColorBrightness, createGradient} from "../../../shared/Constants";

const AddFieldModal = props => {
  const [fieldName, setFieldName] = React.useState('');
  const [fieldContent, setFieldContent] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [adjustColor,setAdjustColor] =React.useState('');

  const icons = [...iconList];

  const filteredIcons = icons.filter(icon =>
    icon.toLowerCase().match(searchText.toLowerCase()),
  );

  const addProfileFieldHandler = () => {
    if (formIsValid()) {
      props.onAddProfileField(props.selectedIcon, fieldName, fieldContent);
      props.setSelectedIcon('');
      props.setModalIsOpen(false);
    }
  };

  const formIsValid = () => {
    if (props.selectedIcon === '') {
      showToast('Please select an icon');
    } else if (fieldName === '') {
      showToast('Field name cannot be empty');
      return false;
    } else {
      return true;
    }
  };

  React.useEffect(() => {
    setAdjustColor(adjustColorBrightness(props.color, 60));
  }, []);

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style:{background:`linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color,35)} 100%)`}
      });
  };

  return (
    <Backdrop onClick={() => props.setModalIsOpen(false)}>
      <ToastContainer />
      <div className={classes.AddFieldModal}>
        <div className={classes.LeftContainer}>
          <div className={classes.CloseButtonContainerLeft}>
            <div onClick={() => props.setModalIsOpen(false)}>
              <MdOutlineClose size={20} color="#C5C5C5" />
            </div>
          </div>
          <div className={classes.SelectedIconContainer}>
            <p>Select a Field Icon</p>
          </div>

          <div
            className={classes.SearchInputWrapper}>
            <FiSearch color="#8f8f8f" size={20} />
            <input
              placeholder="Search icons..."
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>
          <div className={classes.IconContainer}>
            <div>
              {filteredIcons.map(icon => (
                <IconPickerItem
                  icon={icon}
                  size={25}
                  color={props.selectedIcon === icon ? 'white' : '#939393'}
                  key={icon}
                  onClick={() => props.setSelectedIcon(icon)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={classes.RightContainer}>
          <div className={classes.CloseButtonContainerRight}>
            <div onClick={() => props.setModalIsOpen(false)}>
              <MdOutlineClose size={20} color="#C5C5C5" />
            </div>
          </div>
          <div className={classes.RightContainerContent}>
            <div className={classes.InputContainer}>
              <p>Add Field Data</p>
              <div
                style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor,100)} 100%)`}}
                className={classes.InputWrapper}>
                <div className={classes.Input}>
                  <FiAward size={20} color="#919191" />

                  <input
                    placeholder="Field Name"
                    value={fieldName}
                    onChange={e => setFieldName(e.target.value)}
                  />
                </div>
              </div>
              <div
                style={{background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(adjustColor,100)} 100%)`}}
                className={classes.InputWrapper}>
                <div className={classes.Input}>
                  <FiList size={20} color="#919191" />

                  <input
                    placeholder="Field Content"
                    value={fieldContent}
                    onChange={e => setFieldContent(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button
              style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color,50)} 100%)`}}
              className={classes.SaveFieldButton}
              onClick={addProfileFieldHandler}
            >
              Save Field
            </button>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onAddProfileField: (icon, fieldName, fieldContent) =>
      dispatch(actions.addProfileField(icon, fieldName, fieldContent)),
  };
};

export default connect(null, mapDispatchToProps)(AddFieldModal);

import React from 'react';
import classes from './LoginForm.module.scss';
import Input from '../../../UI/Input/Input';
import {RiUser3Line} from 'react-icons/ri';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as actions from '../../../../store/actions';
import {connect} from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import {useNavigate} from 'react-router-dom';


const LoginForm = props => {
    const navigate = useNavigate();

    const tenantName = process.env.REACT_APP_TENANT_NAME;
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const onSignInHandler = () => {
        if (formIsValid()) {
            props.onSignIn(tenantName, username, password, showToast);
        }
    };

    const formIsValid = () => {
        if (username === '') {
            showToast('Username cannot be empty!');
            return false;
        } else if (password === '') {
            showToast('Password cannot be empty!');
            return false;
        } else {
            return true;
        }
    };

    const focusByEnter = (event) => {
        if (event.key === 'Enter' && username !== ""){
            document.getElementById("txtPassword").focus();
        }
    }

    const loginByEnter = (event) => {
        if (event.key === 'Enter') {
            onSignInHandler();
        }
    }

    const showToast = message => {
        toast(message, {
            position: 'top-center',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: classes.CustomToast,
        });
    };

    return (
      <div className={classes.LoginFormContainer}>
          <div className={classes.LoginForm}>
              <Input
                onKeyDown={focusByEnter}
                type="text"
                placeholder="Username"
                icon={<RiUser3Line/>}
                value={username}
                onChange={event => setUsername(event.target.value)}
              />
              <Input
                id="txtPassword"
                onKeyDown={loginByEnter}
                type="password"
                placeholder="Password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
              <div
                onClick={() => navigate('/reset-password')}
                className={classes.ForgotPassword}>
                  <span>Forgot Password?</span>
              </div>
              {props.loading ? (
                <div className={classes.SpinnerContainer}>
                    <ClipLoader color={'#f5833b'} loading={true} size={20}/>
                </div>
              ) : (
                <button className={classes.LoginButton} onClick={onSignInHandler}>
                    Login
                </button>
              )}

              <div
                className={classes.RegisterNowContainer}
                onClick={() => navigate('/register')}
              >
                  <p>New Here?</p>
                  <p>Register Now!</p>
              </div>
          </div>
          <ToastContainer/>
      </div>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignIn: (tenantName, username, password, showToast) =>
          dispatch(actions.signIn(tenantName, username, password, showToast)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

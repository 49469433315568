import React from 'react';
import classes from './LoginHeader.module.scss';
import CommercialPrimeLogo from '../../../../assets/images/osest-white.png';

const LoginHeader = () => {
  return (
    <div className={classes.LoginHeaderContainer}>
      <div className={classes.LoginHeader}>
        <h1>
          Manage Your Digital
          <br />
          Business Card
        </h1>
        <div className={classes.TopBox}>
          <img src={CommercialPrimeLogo} alt="Commercial Prime" />
        </div>
        <div className={classes.MiddleBox}></div>
        <div className={classes.BottomBox}></div>
      </div>
    </div>
  );
};

export default LoginHeader;

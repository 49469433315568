import axios from '../../../axios';
import * as actionTypes from './actionTypes';
import jwt_decode from 'jwt-decode';

const signInStart = () => {
  return {
    type: actionTypes.SIGN_IN_START,
  };
};

const signInSuccess = payload => {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
    payload,
  };
};

const signInFail = () => {
  return {
    type: actionTypes.SIGN_IN_FAIL,
  };
};

export const signIn = (tenantName, username, password, showToast) => {
  return dispatch => {
    dispatch(signInStart());
    axios
      .post('/login', {tenantName, username, password})
      .then(res => {
        const data = JSON.parse(localStorage.getItem('prime'));
        let decode = jwt_decode(res.data.token);
        const authData = {
          token: res.data.token,
          user: decode.sub,
          userRole: decode.role,
        };

        dispatch(signInSuccess(authData));
        localStorage.setItem('prime', JSON.stringify(authData));

        if (!data || !data.token) {
          showToast('Logged In Successfully!');
        }
      })
      .catch(err => {
        if (!err.response) {
          showToast('Check Your Internet Connectivity!');
        } else if (err.response.data.error === "Unauthorized") {
          showToast('Incorrect Username or Password!');
        } else {
          showToast('Something went wrong!');
        }
        dispatch(signInFail());
      });
  };
};


export const tryAutoSignIn = () => {
  return dispatch => {
    const authData = JSON.parse(localStorage.getItem('prime'));
    if (!authData) {
      dispatch(signOut());
    } else {
      dispatch(signInSuccess(authData));
    }
  };
};

export const signOut = () => {
  localStorage.removeItem('prime');
  return {
    type: actionTypes.SIGN_OUT,
  };
};

export const isUserNameExist = (username) => {
  return async dispatch =>{
    try{
      const response = await axios.post('/api/user-service/exits-username?userName=' + username);
      return response.data;
    }catch(err){
      console.log(err);
      throw err;
    }
  }
}

const registerStart = () => {
  return {
    type: actionTypes.REGISTER_START,
  };
};

const registerEnd = () => {
  return {
    type: actionTypes.REGISTER_END,
  };
};

export const register = (
  firstName,
  accountType,
  lastName,
  companyId,
  contactNo,
  email,
  password,
  registrationToken,
  tenantName,
  userName,
  successCallback,
  showToast,
) => {
  return dispatch => {
    dispatch(registerStart());
    axios
      .post('/api/user-service/register', {
        firstName,
        accountType,
        lastName,
        companyId,
        contactNo,
        email,
        password,
        registrationToken,
        tenantName,
        userName,
      })
      .then(() => {
        dispatch(registerEnd());
        successCallback();
      })
      .catch(err => {
        if (!err.response) {
          showToast('Check Your Internet Connectivity!');
        } else if (err.response.data.errorCode === 'INVALID_TOKEN') {
          showToast('Invalid Registration Token!')
        }else if (err.response.data.errorCode === 'DUPLICATE_USERNAME') {
          showToast('Username already Exists!');
        }else if (err.response.data.errorCode === 'DUPLICATE_EMAIL'){
          showToast('Email already Exists!');
        } else {
          showToast('Something went wrong!');
        }
        dispatch(registerEnd());
      });
  };
};

export const sendEmailForForgotPassword = (email) => {
  return async () => {
    try {
      return await axios.post('/api/user-service/email-for-forget-password', {
        email,
        tenantName: process.env.REACT_APP_TENANT_NAME,
      })
    } catch (error) {
      return false;
    }
  };
};

export const verifyOTP = (object) => {
  return async () => {
    try {
      const value=  await axios.post('/api/user-service/otp-for-forget-password', object);
      return value.data;
    } catch (err) {
      return false;
    }
  };
};

export const changeForgotPassword = (object) => {
  return async () => {
    try {
      const value = await axios.post('/api/user-service/forget-password', object);
      return value.data;
    } catch (err) {
      return false;
    }
  };
};


import classes from './Sidebar.module.scss';
import {FiUsers} from 'react-icons/fi';
import {BsFillCreditCardFill} from 'react-icons/bs';
import {BiBuildings} from 'react-icons/bi';
import {CgMenuLeft,CgMenuRight} from 'react-icons/cg';
import {useLocation, useNavigate} from "react-router-dom";
import {MdOutlineBrandingWatermark} from "react-icons/md";

const SideBar = ({open, setOpen, color}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickExpand = () =>{
    setOpen(!open);
  }

  const onNavigate = path => {
    navigate(path);
  }

  return (
   <section
     style={{display:location.pathname === '/home/teams/create-profile' ? "none" : null}}
     className={open ? classes.SidebarWrapper : classes.SidebarWrapperSmall}
   >
      <div className={classes.Head}>
        <span>TEAMS</span>
        {open ?
          <CgMenuRight onClick={onClickExpand} className={classes.ToggleButton}/>
          : <CgMenuLeft onClick={onClickExpand} className={classes.ToggleButton}/>
        }

      </div>
     <div
       className={location.pathname === '/home/teams' ? classes.ItemSelected : classes.MenuItems}
       style={{background:location.pathname === '/home/teams' ? color: ''}}
       onClick={() => onNavigate('/home/teams')}
     >
       <FiUsers/>
       <span>Users</span>
     </div>
     <div
       className={location.pathname === '/home/teams/profiles' ? classes.ItemSelected : classes.MenuItems}
       style={{background:location.pathname === '/home/teams/profiles' ? color: ''}}
       onClick={() => onNavigate('/home/teams/profiles')}
     >
       <BsFillCreditCardFill/>
       <span>Profiles</span>
     </div>
     <div
       className={location.pathname === '/home/teams/company-details' ? classes.ItemSelected : classes.MenuItems}
       style={{background:location.pathname === '/home/teams/company-details' ? color: ''}}
       onClick={() => onNavigate('/home/teams/company-details')}
     >
       <BiBuildings/>
       <span>Company Details</span>
     </div>
     <div
       className={location.pathname === '/home/teams/branding' ? classes.ItemSelected : classes.MenuItems}
       style={{background:location.pathname === '/home/teams/branding' ? color: ''}}
       onClick={() => onNavigate('/home/teams/branding')}
     >
       <MdOutlineBrandingWatermark/>
       <span>Branding</span>
     </div>
   </section>
  );
};

export default SideBar;


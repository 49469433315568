import React from 'react';
import classes from './Header.module.scss';
import {BiDotsVerticalRounded} from 'react-icons/bi';
import {HiMenuAlt2} from 'react-icons/hi';

const Header = props => {
  return (
    <div className={classes.Header}>
      <div
        className={classes.SideButtonContainer}
        onClick={props.toggleDropdownVisibility}
      >
        <BiDotsVerticalRounded size={24} color="#BCBCBC" />
      </div>
      <p className={classes.Title}>My Contacts</p>
      <div className={classes.SideButtonContainer} onClick={props.toggleMenu}>
        <HiMenuAlt2 size={24} color="#BCBCBC" />
      </div>
    </div>
  );
};

export default Header;

import React from 'react';
import classes from './DashboardHeader.module.scss';
import {FaPowerOff} from 'react-icons/fa';
import Logo from '../../../assets/images/OsestLogo.png';
import MiniLogo from '../../../assets/images/osest-white.png';
import {HiMenuAlt2} from 'react-icons/hi';
import {BiDotsVerticalRounded} from 'react-icons/bi';
import {useLocation} from 'react-router-dom';
import Backdrop from '../../UI/Backdrop/Backdrop';
import StatusChangeConfirmation from '../DashboardBody/Home/StatusChangeConfirmation/StatusChangeConfirmation';
import LogOutConfirmation from "./LogOutConfirmation/LogOutConfirmation";
import {connect} from 'react-redux';
import {ClipLoader} from 'react-spinners';
import {HiQrcode} from "react-icons/hi";
import QRCodePopup from "../DashboardBody/Home/QRCodePopup/QRCodePopup";

const DashboardHeader = props => {
  const location = useLocation();

  const [activationStatusPopupVisible, setActivationStatusPopupVisibility] =
    React.useState(false);

  const [qrCodePopupVisible, setQrCodePopupVisibility] =React.useState(false);

  const [logoutConfirmationPopupVisible, setLogoutConfirmationPopupVisible] = React.useState(false);

  const dashboardHeaderStyles = [classes.DashboardHeader];

  if (
    location.pathname === '/home/contacts' ||
    location.pathname === '/home/link' ||
    location.pathname === '/home/account' ||
    location.pathname === '/home/analytics' ||
    location.pathname.includes('/home/teams')
  ) {
    dashboardHeaderStyles.push(classes.DashboardHeaderHidden);
  }

  return (
    <div className={dashboardHeaderStyles.join(' ')}>
      {activationStatusPopupVisible ? (
        <Backdrop onClick={() => setActivationStatusPopupVisibility(false)}>
          <StatusChangeConfirmation
            onClose={() => setActivationStatusPopupVisibility(false)}
          />
        </Backdrop>
      ) : null}

      {logoutConfirmationPopupVisible ? (
        <Backdrop onClick={() => setLogoutConfirmationPopupVisible(false)}>
          <LogOutConfirmation
            color={props.color}
            onClose={() => setLogoutConfirmationPopupVisible(false)}
          />
        </Backdrop>
      ) : null}

      {qrCodePopupVisible ? (
        <Backdrop onClick={() => setQrCodePopupVisibility(false)}>
          <QRCodePopup
            onClose={() => setQrCodePopupVisibility(false)}
            color={props.color}
          />
        </Backdrop>
      ) : null}

      <div className={classes.HeaderTop}>
        {location.pathname === '/home/contacts' ? (
          <div
            className={classes.SideButtonContainer}
            onClick={props.toggleDropdownVisibility}
          >
            <BiDotsVerticalRounded size={24} color="#BCBCBC"/>
          </div>
        ) : location.pathname === '/home' ? (
          <>
            <img src={Logo} alt={'logo'} className={classes.Logo}/>

            <FaPowerOff
              id="log-out-tooltip"
              className={classes.Logout}
              size={20}
              onClick={() => {
                setLogoutConfirmationPopupVisible(true)
              }}
            />
          </>
        ) : null}
        <p className={classes.PageTitle}>
          {location.pathname === '/home/contacts'
            ? 'My Contacts'
            : location.pathname === '/home/link'
              ? 'Shareable Link'
              : location.pathname === '/home/account'
                ? 'Account'
                : location.pathname === '/home/analytics'
                  ? 'Analytics'
                  :location.pathname.includes('/home/teams')
                    ? 'Teams'
                    : ''}
        </p>
        {location.pathname === '/home/contacts' ||
        location.pathname === '/home/link' ||
        location.pathname === '/home/analytics' ||
        location.pathname === '/home/account' ||
        location.pathname.includes('/home/teams') ? (
          <img src={MiniLogo} alt={"osest logo"}  className={classes.MiniLogo}/>
        ) : null}

        <div className={classes.HamburgerContainer} onClick={props.toggleMenu}>
          <HiMenuAlt2 size={24} color="#BCBCBC"/>
        </div>
      </div>
      <div className={classes.HeaderBottom}>
        <div className={classes.Greeting}>
          <h1>Hello,</h1>
          <span style={{color: props.color}}>{props.user}!</span>
        </div>
        <div className={classes.QrContainer}>
          <HiQrcode className={classes.QrButton} onClick={() => setQrCodePopupVisibility(true)}/>
          {props.changingVcardStatus ? (
            <div className={classes.SpinnerContainer}>
              <ClipLoader color={'#dbb659'} loading={true} size={20}/>
            </div>
          ) : (
            <div
              className={
                props.vcardStatus === 'ACTIVE'
                  ? classes.AccountStatusActive
                  : classes.AccountStatusInactive
              }
              onClick={() => setActivationStatusPopupVisibility(true)}
            >
              <div>
              <span>
                {props.vcardStatus === 'ACTIVE'
                  ? 'Card Active'
                  : 'Card Inactive'}
              </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    vcardStatus: state.home.vcardStatus,
    changingVcardStatus: state.home.changingVcardStatus,
    color: state.home.themeColor,
    logo: state.home.logo,
    collaboration: state.home.collaboration,
  };
};

export default connect(mapStateToProps)(DashboardHeader);

import React from 'react';
import classes from './AddNewContactPopup.module.scss';
import {MdClose} from 'react-icons/md';
import {BsBriefcase, BsEnvelope, BsPerson, BsTelephone} from 'react-icons/bs';
import {FiAnchor} from 'react-icons/fi';
import {BiNote} from 'react-icons/bi';
import {ToastContainer, toast} from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import * as actions from '../../../../../store/actions';
import {connect} from 'react-redux';
import {createGradient} from "../../../../../shared/Constants";
import PhoneInput from "react-phone-input-2";
import ct from "countries-and-timezones";
import {isValidPhoneNumber} from "libphonenumber-js";

const AddNewContactPopup = props => {
  const [name, setName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [contactNumber, setContactNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [note, setNote] = React.useState('');

  const onSaveHandler = () => {
    if (formIsValid()) {
      props.onAddNewContact(
        {
          userId: '',
          userName: props.user,
          name,
          contactNo: contactNumber,
          receivedProfile: props.profiles.find(profile => profile.active === true).id,
          company,
          title,
          email,
          note,
        },
        showToast,
        props.onClose,
      );
    }
  };

  const formIsValid = () => {
    if (name.trim() === '') {
      showToast('Name cannot be empty');
      return false;
    }

    if (contactNumber.trim() === '') {
      showToast('Contact number cannot be empty');
      return false;
    }

    if (!isValidPhoneNumber(contactNumber)) {
      showToast('Invalid phone number');
      return false;
    }

    if (email.trim() !== '') {
      const atposition = email.indexOf('@');
      const dotposition = email.lastIndexOf('.');

      if (
        atposition === -1 ||
        dotposition === -1 ||
        atposition < 1 ||
        dotposition < atposition + 2 ||
        dotposition + 2 >= email.length
      ) {
        showToast('Invalid email');
        return false;
      }
    }

    return true;
  };

  const getCountry = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz)
    return obj.id.toLowerCase();
  }

  const handlePhoneChange = (phone) => {
    setContactNumber('+' + phone);
  };

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    });
  };

  return (
    <div className={classes.AddNewContactPopup}>
      <ToastContainer/>

      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdClose color="#C5C5C5" size={20}/>
        </div>
      </div>
      <p className={classes.Title}>Contact Details</p>
      <div style={{
        background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 80)} 100%)`
      }}
           className={classes.InputWrapper}>
        <div>
          <div className={classes.IconContainer}>
            <BsPerson color="#919191" size={16}/>
          </div>
          <input
            placeholder="Name"
            inputMode="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </div>
      <div style={{
        background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 80)} 100%)`
      }} className={classes.InputWrapper}>
        <div>
          <div className={classes.IconContainer}>
            <BsBriefcase color="#919191" size={13}/>
          </div>
          <input
            placeholder="Company"
            inputMode="text"
            value={company}
            onChange={e => setCompany(e.target.value)}
          />
        </div>
      </div>
      <div style={{
        background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 80)} 100%)`
      }} className={classes.InputWrapper}>
        <div>
          <div className={classes.IconContainer}>
            <FiAnchor color="#919191" size={13}/>
          </div>
          <input
            placeholder="Title"
            inputMode="text"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </div>
      <div style={{
        background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 80)} 100%)`
      }} className={classes.InputWrapper}>
        <div>
          <div className={classes.IconContainer}>
            <BsTelephone color="#919191" size={13}/>
          </div>
          <PhoneInput
            country={getCountry()}
            placeholder="Contact Number"
            countryCodeEditable={true}
            value={contactNumber}
            onChange={handlePhoneChange}
            enableSearch={true}
            disableSearchIcon={true}
            inputClass={classes.PhoneInput}
            buttonClass={classes.ButtonClass}
            dropdownClass={classes.DropdownClass}
            searchClass={classes.SearchClass}
          />
        </div>
      </div>
      <div style={{
        background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 80)} 100%)`
      }} className={classes.InputWrapper}>
        <div>
          <div className={classes.IconContainer}>
            <BsEnvelope color="#919191" size={13}/>
          </div>
          <input
            placeholder="Email"
            inputMode="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div style={{
        background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 80)} 100%)`
      }} className={classes.InputWrapper}>
        <div>
          <div className={classes.IconContainer}>
            <BiNote color="#919191" size={13}/>
          </div>
          <input
            placeholder="Note"
            inputMode="text"
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </div>
      </div>

      {props.loading ? (
        <div className={classes.SpinnerContainer}>
          <ClipLoader color={props.color} loading={true} size={20}/>
        </div>
      ) : (
        <button style={{
          background: `linear-gradient(91.3deg, ${props.color} 1.61%, ${createGradient(props.color, 50)} 100%)`
        }}
                className={classes.SaveButton}
                onClick={onSaveHandler}
        >
          Save
        </button>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    loading: state.contact.loading,
    profiles: state.home.profiles,
    color: state.home.themeColor,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddNewContact: (contactData, showToast, closePopup) =>
      dispatch(actions.addNewContact(contactData, showToast, closePopup)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewContactPopup);

/*
import Header from "./Header/Header";
import {useOutletContext} from "react-router-dom";
import classes from './Analytics.module.scss';
import React from "react";
import {connect} from "react-redux";
import BackgroundTop from "../../../../assets/images/createProfile/backgroundTop.png";
import BackgroundBottom from "../../../../assets/images/createProfile/backgroundBottom.png";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {faker} from '@faker-js/faker';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const Analytics = props => {
  const outletContext = useOutletContext();

  const hexToRgb = (hex) => {
    hex = hex.replace('#', '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Views & Engagements Over Time',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
          onZoom: ({ chart }) => {
            const chartType = chart.config.type;
            const axis = chart.scales['x'];
            const { min, max } = axis.options.ticks;

            // Define different date format based on the chart type
            let dateFormat = 'MMM D, YYYY';
            if (chartType === 'bar') {
              dateFormat = 'MMMM YYYY';
            } else if (chartType === 'line') {
              if (max - min < 7 * 86400000) {
                dateFormat = 'MMM D, YYYY';
              } else if (max - min < 4 * 7 * 86400000) {
                dateFormat = 'MMM D';
              } else if (max - min < 12 * 4 * 7 * 86400000) {
                dateFormat = 'MMM YYYY';
              } else {
                dateFormat = 'YYYY';
              }
            }

            // Update tick format based on the date range
            axis.options.time.unit = dateFormat;
            axis.options.ticks.callback = (value) => moment(value).format(dateFormat);
          },
        },
        resetButton: {
          text: 'Reset Zoom',
          enabled: true,
        }
      },
    },
  };

  const data = {
    datasets: [
      {
        label: 'Views',
        data: [...Array(7)].map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: props.color,
        backgroundColor: hexToRgb(props.color),
      },
      {
        label: 'Engagements',
        data: [...Array(7)].map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: 'rgb(255,255,255)',
        backgroundColor: 'rgba(255,255,255,0.5)',
      },
    ],
  };

// Set initial labels for the last 7 days
  data.labels = [...Array(7)].map((_, i) => moment().subtract((i + 1) - 1, 'days').format('MMM D'));


  return (
    <div className={classes.Analytics}>
      <Header toggleMenu={outletContext.toggleMenu}/>
      <div style={{backgroundColor: props.color}} className={classes.LeftContainer}>
        <img src={BackgroundTop} alt="commercial prime"/>
        <img src={BackgroundBottom} alt="commercial prime"/>
        <p>
          You can see your
          <br/>
          growth of sharing
        </p>
      </div>

      <div className={classes.RightContainer}>
        <div className={classes.CardContainer}>
          <div className={classes.Card}>
            <span>Link Views</span>
            <h2>0</h2>
          </div>
          <div className={classes.Card}>
            <span>Engagements</span>
            <h2>0</h2>
          </div>
          <div className={classes.Card}>
            <span>Contact Savings</span>
            <h2>0</h2>
          </div>
          <div className={classes.Card}>
            <span>Received Contacts </span>
            <h2>0</h2>
          </div>
        </div>
        <div className={classes.ChartContainer}>
          <Line options={options} data={data}/>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    color: state.home.themeColor,
  };
};

export default connect(mapStateToProps)(Analytics);
*/

import Header from "./Header/Header";
import {useOutletContext} from "react-router-dom";
import classes from './Analytics.module.scss';
import React from "react";
import {connect} from "react-redux";
import SVG from "react-inlinesvg";
import analytics from "../../../../assets/images/analytics/Rising-bro.svg"
import BackgroundTop from "../../../../assets/images/createProfile/backgroundTop.png";
import BackgroundBottom from "../../../../assets/images/createProfile/backgroundBottom.png";

const Analytics = props => {
  const outletContext = useOutletContext();

  return(
    <div className={classes.Analytics}>
      <Header toggleMenu={outletContext.toggleMenu}/>
      <div style={{backgroundColor: props.color}} className={classes.LeftContainer}>
        <img src={BackgroundTop} alt="commercial prime"/>
        <img src={BackgroundBottom} alt="commercial prime"/>
        <p>
          You can see your
          <br/>
          growth of sharing
        </p>
      </div>

      <div className={classes.RightContainer}>
        <p>
          Hold Tight!
          <br/>
          Analytics are coming soon
        </p>

        <SVG src={analytics} fill={props.color} className={classes.GraphImage}/>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    color: state.home.themeColor,
  };
};

export default connect(mapStateToProps)(Analytics);


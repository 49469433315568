import * as actionTypes from './actionTypes';
import axios from "../../../axios";

const getLink = link =>{
    return{
        type:actionTypes.GET_LINK_BY_USERNAME,
        link,
    }
}

export const getLinkByUsername = username =>{
    return dispatch =>{
        axios
            .get(`/api/product-service/get-link-by-userName?userName=${username}`)
            .then(response =>{
                dispatch(getLink(response.data[0].link));
            })
            .catch((e=>console.log(e)));
    }
}

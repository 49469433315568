import React, {useState} from 'react';
import classes from './Input.module.scss';

import {BiLockAlt} from 'react-icons/bi';
import {RiEyeFill, RiEyeOffFill} from 'react-icons/ri';

const Input = props => {
  const isPassword = props.type === 'password' ? true : false;

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  let type = null;
  let icon = props.icon;
  switch (props.type) {
    case 'text':
      type = 'text';
      break;
    case 'password':
      type = 'password';
      break;
    case 'email':
      type = 'email';
      break;
    case 'number':
      type = 'number';
      break;
    default:
      type = 'text';
  }

  if (props.type === 'password') {
    icon = <BiLockAlt/>;
  }

  const viewPasswordOnClickHandler = () => {
    if (!isPasswordShown) {
      setIsPasswordShown(true);
      type = 'text';
    } else {
      setIsPasswordShown(false);
    }
  };

  return (
    <div className={classes.Input}>
      <div className={classes.IconContainer}>{icon}</div>
      <input
        id={props.id}
        onKeyDown={props.onKeyDown}
        autoComplete={'new-password'}
        type={!isPassword ? type : isPasswordShown ? 'text' : 'password'}
        placeholder={props.placeholder}
        className={isPassword ? classes.NoBorderRadius : null}
        onChange={props.onChange}
        value={props.value}
        disabled={props.isDisabled}
      />
      {isPassword ? (
        <div
          className={classes.ViewPassword}
          onClick={viewPasswordOnClickHandler}
        >
          {isPasswordShown ? <RiEyeOffFill/> : <RiEyeFill/>}
        </div>
      ) : null}
    </div>
  );
};

export default Input;

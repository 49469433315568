import classes from "./ForgotPasswordBody.module.scss";
import ClipLoader from "react-spinners/ClipLoader";
import {toast, ToastContainer} from "react-toastify";
import React from "react";
import FirstStep from "./FirstStep/FirstStep";
import SecondStep from "./SecondStep/SecondStep";
import ThirdStep from "./ThirdStep/ThirdStep";
import {connect} from 'react-redux';
import * as actions from '../../../../store/actions';
import {useNavigate} from "react-router-dom";

const ForgotPasswordBody = (props) => {

  const [step, setStep] = React.useState(0);
  const [email, setEmail] = React.useState('');
  const [otp, setOtp] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const tenantName = process.env.REACT_APP_TENANT_NAME;
  const navigate = useNavigate();

  const onButtonClick = async () => {
    if (step === 0) {
      if (email !== '')
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          const value = await props.onSendEmailForForgotPassword(email)
          if (value) {
            showToast("Please Check your Email Inbox!");
            setStep(1)
          } else {
            showToast("Sorry, Try Again!");
          }
        } else {
          showToast("Please Enter Valid Email");
        }
      else {
        showToast('Please enter your Email');
      }

    } else if (step === 1) {
      if (otp !== '')
        if (/^[0-9a-zA-Z]*$/.test(otp)) {
          const object = {
            email,
            otp,
            tenantName,
          };
          const value = await props.onVerifyOTP(object)
          if (value) {
            showToast("Your Email is Verified")
            setStep(2)
          } else {
            showToast("Token is Invalid!")
          }
        } else {
          showToast("Access only Numbers and Letters")
        }
      else {
        showToast('OTP Cannot be Empty')
      }
    } else {
      if (password === '') {
        showToast('Please enter a password');
      } else if (confirmPassword === '') {
        showToast("Confirm Password cannot be Empty");
      } else if (password.length < 6) {
        showToast('Password is too short');
      } else if (!/^(?=.*[A-Za-z])(?=.*\d).+$/.test(password)) {
        showToast('Use letters, numbers & Simbols');
      } else if (!/^[a-zA-Z0-9]*[@#$%^&+=_\-!¡?¿;:'",.<>\{\}\[\]\\/\|~`(•√π÷×¶∆£¢€¥^°={}\*]+/.test(password)) {
        showToast('Please use special characters');
      } else if (password !== confirmPassword) {
        showToast('Passwords do not match!');
      } else {
        const object = {
          email,
          password,
          tenantName,
        };
        const value = props.onChangeForgotPassword(object);

        if (value) {
          showToast('Password Recovery Success')
          setTimeout(() => navigate('/'), 1500);
        } else {
          showToast('Sorry, Please try again!');
        }
      }
    }
  };

  const setEmailToState = (email) => {
    setEmail(email);
  };

  const setOtpToState = (code) => {
    setOtp(code);
  };

  const setPasswordToState = (password) => {
    setPassword(password);
  }

  const setConfirmPasswordToState = (password) => {
    setConfirmPassword(password);
  }

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
    });
  };

// Define a switch case to render the appropriate component based on the current step
  let component;
  switch (step) {
    case 0:
      component = <FirstStep setEmail={setEmailToState} email={email}/>;
      break;
    case 1:
      component = <SecondStep setOtp={setOtpToState} email={email}/>;
      break;
    case 2:
      component = <ThirdStep
        password={password}
        setPassword={setPasswordToState}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPasswordToState}
      />;
      break;
    default:
      component = <FirstStep setEmail={setEmailToState} email={email}/>;
  }

  return (
    <div className={classes.ForgotPasswordBodyContainer}>
      <div className={classes.ForgotPasswordBody}>

        {component}

        <div>
          <div className={classes.StepperContainer}>
            <div className={step === 0 ? classes.ActiveStep : classes.InactiveStep}></div>
            <div className={step === 1 ? classes.ActiveStep : classes.InactiveStep}></div>
            <div className={step === 2 ? classes.ActiveStep : classes.InactiveStep}></div>
          </div>

          {props.loading ? (
            <div className={classes.SpinnerContainer}>
              <ClipLoader color={'#f5833b'} loading={true} size={20}/>
            </div>
          ) : (
            <button
              className={classes.SendButton}
              onClick={onButtonClick}
            >
              {step === 0 ? 'Send' : step === 1 ? 'Verify' : 'Save'}
            </button>
          )}
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onSendEmailForForgotPassword: (email) =>
      dispatch(actions.sendEmailForForgotPassword(email)),
    onVerifyOTP: (object) =>
      dispatch(actions.verifyOTP(object)),
    onChangeForgotPassword: (object) =>
      dispatch(actions.changeForgotPassword(object)),
  };
};

export default connect(null, mapDispatchToProps)(ForgotPasswordBody);

import React from 'react';
import classes from './ProfileCard.module.scss';
import {BsEye} from 'react-icons/bs';
import {useLocation, useNavigate} from 'react-router-dom';
import * as actions from '../../../../../store/actions';
import {connect} from 'react-redux';

const ProfileCard = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const firstName = props.profile.data.find(
    dataObj => dataObj.name === 'First Name',
  );

  const lastName = props.profile.data.find(
    dataObj => dataObj.name === 'Last Name',
  );

  const title = props.profile.data.find(dataObj => dataObj.name === 'Title');

  const company = props.profile.data.find(
    dataObj => dataObj.name === 'Company',
  );

  const onSelectProfileHandler = () => {
    const homeEndPoint = 'api/user-service/get-profile';
    const teamsEndPoint = 'api/company-service/get-teams-user-profile';

    if (location.pathname === '/home/teams/profiles') {
      props.onSelectProfile(teamsEndPoint, props.profile.id);
      navigate(`/home/teams/create-profile?edit=${props.profile.id}`);
    } else {
      props.onSelectProfile(homeEndPoint, props.profile.id);
      navigate(`/create-profile?edit=${props.profile.id}`);
    }
  };

  return (
    <div
      className={classes.ProfileCard}
      style={{
        borderColor: props.profile.active ? props.color : '#3d3d3d',
      }}
      onClick={onSelectProfileHandler}
    >
      <div className={classes.ProfileContainer}>
        <div className={classes.CardHeader}>
          {location.pathname !== '/home/teams/profiles' &&
            <div
              className={classes.ViewButton}
              onClick={event => {
                props.showConfirmation(props.profile.id);
                event.stopPropagation();
              }}
            >
              <BsEye color="#A8A8A8" size={16}/>
            </div>
          }
          {location.pathname !== '/home/teams/profiles' &&
            <div
              className={
                props.profile.active
                  ? classes.StatusActive
                  : classes.StatusInactive
              }
              onClick={event => {
                props.onChangeActiveProfileHandler(props.profile.id);
                event.stopPropagation();
              }}
            >
              <p>Inactive</p>
              <p style={{
                backgroundColor: props.profile.active ? props.color : null,
                borderColor: props.profile.active ? props.color : null,
              }}>
                Active
              </p>
            </div>
          }

        </div>
        <div style={{color: props.profile.active ? props.color : classes.ProfileInfoContainerInactive}} className={
          props.profile.active
            ? classes.ProfileInfoContainerActive
            : classes.ProfileInfoContainerInactive
        }>
          <h2>
            {firstName?.value} {lastName?.value}
          </h2>
          <p>{title?.value}</p>
          <span>{company?.value}</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userId: state.auth.user.id,
    color: state.home.themeColor,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSelectProfile: (endPoint, profileId) =>
      dispatch(actions.selectProfile(endPoint, profileId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
